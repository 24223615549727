import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import { Layout, GridContainer, GridChild } from "../components/layout"
import { PortableText } from "../components/content"
import { SEO } from "../components/meta"

const Letter = ({ data }) => {
  const doc = data.sanityLetter

  return (
    <Layout>
      <SEO input={doc} />
      <GridContainer gridTemplate="repeat(4, 1fr)" maxWidth="600px">
        <GridChild gridColumn="1 / span 4">
          {doc.image && (
            <Img fluid={doc.image.asset.fluid} alt={doc.image.alt} />
          )}
          <h1>{doc.title}</h1>
          {doc._rawBody && <PortableText blocks={doc._rawBody} />}
        </GridChild>
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query LetterQuery($id: String!) {
    sanityLetter(id: { eq: $id }) {
      id
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
      image {
        alt
        asset {
          url
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default Letter
